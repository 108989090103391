// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "input[type=\"text\"].JLxQJbICR094XMY4QKS-YA\\=\\= {\n  box-sizing: border-box;\n  width: 400px;\n  height: 34px;\n  line-height: 34px;\n  padding: 0 12px 0 27px;\n  margin: 1px 0 0 1px;\n}\n", "",{"version":3,"sources":["webpack://./shared/AppFrame/SearchPupilsModal.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,YAAY;EACZ,YAAY;EACZ,iBAAiB;EACjB,sBAAsB;EACtB,mBAAmB;AACrB","sourcesContent":["input[type=\"text\"].searchInput {\n  box-sizing: border-box;\n  width: 400px;\n  height: 34px;\n  line-height: 34px;\n  padding: 0 12px 0 27px;\n  margin: 1px 0 0 1px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchInput": "JLxQJbICR094XMY4QKS-YA=="
};
export default ___CSS_LOADER_EXPORT___;
