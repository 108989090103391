// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".STxA6d9\\+HjzvPyRfviYVuw\\=\\= {\n  padding: 8px 35px 8px 14px;\n  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);\n  background-color: #fcf8e3;\n  border: 1px solid #fbeed5;\n  -webkit-border-radius: 4px;\n  -moz-border-radius: 4px;\n  border-radius: 4px;\n}\n\n.rNzoQIjq-I2RI0wwY6\\+ffg\\=\\= {\n  background-color: #f2dede;\n  border-color: #eed3d7;\n  color: #b94a48;\n}\n\n.ISEtnb6Sw-X7sJP5pI2F8w\\=\\= {\n  background-color: #fcf8e3;\n  border: 1px solid #fbeed5;\n  color: #b56400;\n}\n\n.fy9o\\+Ev8oFZpD\\+jxXsJj7A\\=\\= {\n  background-color: #d9edf7;\n  border-color: #bce8f1;\n  color: #3a87ad;\n}\n\n.aucBs7FHtqz9GeuPTPKMVA\\=\\= {\n  background-color: #dff0d8;\n  border-color: #d6e9c6;\n  color: #468847;\n}\n", "",{"version":3,"sources":["webpack://./shared/Alert.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,6CAA6C;EAC7C,yBAAyB;EACzB,yBAAyB;EACzB,0BAA0B;EAC1B,uBAAuB;EACvB,kBAAkB;AACpB;;AAEA;EAEE,yBAAyB;EACzB,qBAAqB;EACrB,cAAc;AAChB;;AAEA;EAEE,yBAAyB;EACzB,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EAEE,yBAAyB;EACzB,qBAAqB;EACrB,cAAc;AAChB;;AAEA;EAEE,yBAAyB;EACzB,qBAAqB;EACrB,cAAc;AAChB","sourcesContent":[".alert {\n  padding: 8px 35px 8px 14px;\n  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);\n  background-color: #fcf8e3;\n  border: 1px solid #fbeed5;\n  -webkit-border-radius: 4px;\n  -moz-border-radius: 4px;\n  border-radius: 4px;\n}\n\n.error {\n  composes: alert;\n  background-color: #f2dede;\n  border-color: #eed3d7;\n  color: #b94a48;\n}\n\n.warning {\n  composes: alert;\n  background-color: #fcf8e3;\n  border: 1px solid #fbeed5;\n  color: #b56400;\n}\n\n.info {\n  composes: alert;\n  background-color: #d9edf7;\n  border-color: #bce8f1;\n  color: #3a87ad;\n}\n\n.success {\n  composes: alert;\n  background-color: #dff0d8;\n  border-color: #d6e9c6;\n  color: #468847;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alert": "STxA6d9+HjzvPyRfviYVuw==",
	"error": "rNzoQIjq-I2RI0wwY6+ffg== STxA6d9+HjzvPyRfviYVuw==",
	"warning": "ISEtnb6Sw-X7sJP5pI2F8w== STxA6d9+HjzvPyRfviYVuw==",
	"info": "fy9o+Ev8oFZpD+jxXsJj7A== STxA6d9+HjzvPyRfviYVuw==",
	"success": "aucBs7FHtqz9GeuPTPKMVA== STxA6d9+HjzvPyRfviYVuw=="
};
export default ___CSS_LOADER_EXPORT___;
