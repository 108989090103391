import React, {
  CSSProperties,
  ChangeEvent,
  useEffect,
  useRef,
  useState
} from "react";
import Highlighter from "react-highlight-words";
import Icon from "../Icon";
import Spinner from "../Spinner";
import { insightFetch } from "../insightFetch";
import styles from "./index.css";

type PupilSearchResult = {
  firstName: string;
  lastName: string;
  name: string;
  url: string;
};

function tetherStyle(el: Element): React.CSSProperties {
  const { left, right, bottom } = el.getBoundingClientRect();
  return {
    display: "block",
    left,
    width: right - left,
    top: bottom,
    position: "fixed"
  };
}

function Highlight({ children }: { children: React.ReactNode }) {
  return <strong>{children}</strong>;
}

type SearchPupilsProps = {
  link: string;
  inputClassName?: string;
  autoFocus?: boolean;
};

export default function SearchPupils({
  link,
  inputClassName,
  autoFocus
}: SearchPupilsProps) {
  const [searchText, setSearchText] = useState("");
  const [popup, setPopup] = useState(false);
  const [list, setList] = useState<PupilSearchResult[]>([]);
  const [searching, setSearching] = useState(false);
  const [error, setError] = useState("");
  const [activeIndex, setActiveIndex] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  // When searchText changes, trigger a search.
  useEffect(() => {
    setSearching(true);

    // Timeout to prevent spamming many requests during typing.
    const id = setTimeout(() => requestSearch(), 200);
    let cancelled = false;
    return () => {
      cancelled = true;
      clearTimeout(id);
    };

    async function requestSearch() {
      if (!searchText.trim()) {
        setList([]);
        setSearching(false);
        return;
      }

      try {
        const response = await insightFetch(`${link}?filter=${searchText}`);
        if (cancelled) return;

        if (response.ok) {
          const results: PupilSearchResult[] = await response.json();
          if (cancelled) return;
          setPopup(true);
          setList(results);
          setActiveIndex(0);
        } else if (response.status === 400) {
          const { message } = await response.json();
          if (cancelled) return;
          throw new Error(message);
        } else {
          throw new Error("There was a problem searching.");
        }
      } catch (error) {
        setError(error.message);
        setList([]);
      } finally {
        setSearching(false);
      }
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps  */
  }, [searchText]);

  useEffect(() => {
    // When a click happens outside of this search component,
    // then we hide the popup.
    // This is instead of using the input's onBlur event,
    // which is tricky when need to click the search result elements.
    function documentClicked(e: MouseEvent) {
      const isClickOutsideComponent =
        ref.current && !ref.current.contains(e.target as Node);
      if (isClickOutsideComponent) {
        setPopup(false);
      }
    }
    document.documentElement.addEventListener("click", documentClicked, false);
    return () =>
      document.documentElement.removeEventListener(
        "click",
        documentClicked,
        false
      );
  }, []);

  function onChange(e: ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    setSearchText(value);
    setError("");
  }

  function onKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    switch (e.key) {
      case "ArrowDown":
        e.stopPropagation();
        e.preventDefault();
        if (activeIndex < list.length - 1) setActiveIndex(activeIndex + 1);
        break;

      case "ArrowUp":
        e.stopPropagation();
        e.preventDefault();
        if (activeIndex > 0) setActiveIndex(activeIndex - 1);
        break;

      case "Enter":
        e.stopPropagation();
        e.preventDefault();
        if (list.length > 0) {
          gotoPupil(activeIndex);
        }
        break;

      case "Tab":
        setPopup(false);
        break;

      case "Escape":
        setPopup(false);
        setSearchText("");
        setList([]);
        break;
    }
  }

  function onClick(
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    index: number
  ) {
    e.preventDefault();
    e.stopPropagation();
    gotoPupil(index);
  }

  function gotoPupil(index: number) {
    window.location.assign(list[index].url);
  }

  const popupPosition = ref.current && tetherStyle(ref.current);

  return (
    <div ref={ref} className={styles.search}>
      <label htmlFor="header-pupil-search">
        {searching ? <Spinner /> : <Icon icon="search" />}
      </label>
      <input
        className={inputClassName || styles.searchInput}
        placeholder="Search pupils..."
        id="header-pupil-search"
        type="text"
        value={searchText}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onFocus={() => setPopup(true)}
        autoFocus={autoFocus}
      />
      {popup && list.length > 0 && (
        <SearchResultsPopup
          list={list}
          searchText={searchText}
          activeIndex={activeIndex}
          onClick={onClick}
          positionStyles={popupPosition}
        />
      )}
      {popup && searchText && list.length === 0 && !error && !searching && (
        <MessagePopup positionStyles={popupPosition}>
          No pupils found
        </MessagePopup>
      )}
      {popup && searchText && error && (
        <MessagePopup error positionStyles={popupPosition}>
          {error}
        </MessagePopup>
      )}
    </div>
  );
}

type SearchResultsProps = {
  positionStyles: CSSProperties;
  list: PupilSearchResult[];
  activeIndex: number;
  searchText: string;
  onClick: (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    index: number
  ) => void;
};

function SearchResultsPopup({
  positionStyles,
  list,
  activeIndex,
  searchText,
  onClick
}: SearchResultsProps) {
  return (
    <ul className="dropdown-menu" style={positionStyles}>
      {list.map((v, i) => (
        <li
          key={i}
          style={{ lineHeight: "20px" }}
          className={activeIndex == i ? "active" : ""}
        >
          <a
            href={v.url}
            onClick={e => onClick(e, i)}
            style={{ cursor: "pointer" }}
          >
            <Highlighter
              highlightTag={Highlight}
              searchWords={searchText.split(" ")}
              autoEscape={true}
              textToHighlight={v.name}
            />
          </a>
        </li>
      ))}
    </ul>
  );
}

type MessagePopupProps = {
  children: React.ReactNode;
  error?: boolean;
  positionStyles: CSSProperties;
};

function MessagePopup({ children, error, positionStyles }: MessagePopupProps) {
  return (
    <ul className="dropdown-menu" style={positionStyles}>
      <li style={{ padding: "4px 20px", color: error ? "#800" : null }}>
        {children}
      </li>
    </ul>
  );
}
