import * as React from "react";
import { useCurrentUser } from "../useCurrentUser";
import { SecondaryLink } from "./Menus";
import useToggleHelp from "./useToggleHelp";

export function HelpLink({
  organisationId,
  organisationName
}: {
  organisationId: string;
  organisationName: string;
}) {
  const user = useCurrentUser();
  const toggleHelp = useToggleHelp(user, organisationId, organisationName);
  return <SecondaryLink text="Help" onClick={toggleHelp} />;
}
