import React, { useEffect, useReducer, useState } from "react";
import { GetSchoolLayoutData } from "@server-types/schools";
import { GetTrustLayoutData } from "@server-types/trusts";
import { GetRootLayoutData } from "@server-types/users";
import { AppConfig, AppConfigProvider } from "../AppConfig";
import { insightFetch } from "../insightFetch";
import { CurrentUserProvider } from "../useCurrentUser";
import {
  HelpModal,
  HelpModalContext,
  useHelpModalController
} from "./HelpModal";
import LogErrorToServer from "./LogErrorToServer";
import { MyAccountMenu } from "./Menus";
import { PageHiddenForPrivateMode } from "./PageHiddenForPrivateMode";
import SchoolNavBar from "./SchoolNavBar";
import TrustNavBar from "./TrustNavBar";
import styles from "./index.css";

// AppFrames are designed to be very top-level of React app page.
// They include error boundary and nav bar.

type LayoutData = GetRootLayoutData | GetSchoolLayoutData | GetTrustLayoutData;

function useFetchLayout(layoutUrl: string): [LayoutData, VoidFunction] {
  const [layout, setLayout] = useState(null);
  const [refreshToken, refresh] = useReducer(x => x + 1, 0);
  useEffect(() => {
    insightFetch(layoutUrl, {
      // If refreshing, then don't want to use cache.
      cache: refreshToken === 0 ? "default" : "no-cache"
    })
      .then(r => r.json())
      .then(setLayout);
  }, [layoutUrl, setLayout, refreshToken]);
  return [layout, refresh];
}

type LayoutProps = {
  /** URL to get layout data from. */
  layoutUrl: string;
  hideInPrivateMode?: boolean;
  children: React.ReactNode;
};

/** Layout for either school or trust page.
 *
 * The type of layout is determined by what the layout data's OrganisationType is. */
export function AppFrame({
  layoutUrl,
  hideInPrivateMode,
  children
}: LayoutProps) {
  const [layout, refresh] = useFetchLayout(layoutUrl);
  const helpModalController = useHelpModalController();

  if (!layout) {
    return <div>Loading...</div>;
  }

  if (!("organisationType" in layout)) {
    throw new Error("Layout data must have an organisationType property.");
  }

  return (
    <LogErrorToServer>
      <AppConfigProvider
        // Awkward cast here due to difference in generated AppConfig type!
        appConfig={layout?.appConfig as AppConfig}
        refresh={refresh}
      >
        <CurrentUserProvider>
          <HelpModalContext.Provider value={helpModalController}>
            <div className={styles.container}>
              {layout && layout.organisationType === "School" && (
                <SchoolNavBar layout={layout} />
              )}

              {layout && layout.organisationType === "Trust" && (
                <TrustNavBar layout={layout} />
              )}

              {layout && helpModalController.isOpen && (
                <HelpModal
                  helpUrl={layout.links.help}
                  onClose={helpModalController.close}
                  isUserflowBlocked={helpModalController.isUserflowBlocked}
                />
              )}

              {hideInPrivateMode && isPrivateMode() ? (
                <PageHiddenForPrivateMode />
              ) : (
                children
              )}
            </div>
          </HelpModalContext.Provider>
        </CurrentUserProvider>
      </AppConfigProvider>
    </LogErrorToServer>
  );
}

export function SchoolAppFrame({
  layoutUrl,
  hideInPrivateMode,
  children
}: LayoutProps) {
  const [layout, refresh] = useFetchLayout(layoutUrl);
  const helpModalController = useHelpModalController();

  if (!layout) {
    return <div>Loading...</div>;
  }

  if (!("organisationType" in layout) || layout.organisationType !== "School") {
    throw new Error(
      "Layout data must have 'School' organisationType property."
    );
  }

  return (
    <LogErrorToServer>
      <AppConfigProvider
        appConfig={layout?.appConfig as AppConfig}
        refresh={refresh}
      >
        <CurrentUserProvider>
          <HelpModalContext.Provider value={helpModalController}>
            <div className={styles.container}>
              {layout ? <SchoolNavBar layout={layout} /> : null}

              {layout && helpModalController.isOpen && (
                <HelpModal
                  helpUrl={layout.links.help}
                  onClose={helpModalController.close}
                  isUserflowBlocked={helpModalController.isUserflowBlocked}
                />
              )}
              {hideInPrivateMode && isPrivateMode() ? (
                <PageHiddenForPrivateMode />
              ) : (
                children
              )}
            </div>
          </HelpModalContext.Provider>
        </CurrentUserProvider>
      </AppConfigProvider>
    </LogErrorToServer>
  );
}

export function TrustAppFrame({
  layoutUrl,
  hideInPrivateMode,
  children
}: LayoutProps) {
  const [layout, refresh] = useFetchLayout(layoutUrl);
  const helpModalController = useHelpModalController();

  if (!layout) {
    return <div>Loading...</div>;
  }

  if (!("organisationType" in layout) || layout.organisationType !== "Trust") {
    throw new Error("Layout data must have 'Trust' organisationType property.");
  }

  return (
    <LogErrorToServer>
      <AppConfigProvider
        appConfig={layout?.appConfig as AppConfig}
        refresh={refresh}
      >
        <CurrentUserProvider>
          <HelpModalContext.Provider value={helpModalController}>
            <div className={styles.container}>
              {layout ? <TrustNavBar layout={layout} /> : null}

              {layout && helpModalController.isOpen && (
                <HelpModal
                  helpUrl={layout.links.help}
                  onClose={helpModalController.close}
                  isUserflowBlocked={helpModalController.isUserflowBlocked}
                />
              )}

              {hideInPrivateMode && isPrivateMode() ? (
                <PageHiddenForPrivateMode />
              ) : (
                children
              )}
            </div>
          </HelpModalContext.Provider>
        </CurrentUserProvider>
      </AppConfigProvider>
    </LogErrorToServer>
  );
}

export function RootAppFrame({ layoutUrl, children }: LayoutProps) {
  const [layout] = useFetchLayout(layoutUrl);

  if (!layout) {
    return <div>Loading...</div>;
  }

  return (
    <LogErrorToServer>
      <div className={styles.container}>
        {layout ? (
          <div className={styles.navbar}>
            <div className={styles.secondaryNav}>
              <MyAccountMenu links={layout.links} />
            </div>
          </div>
        ) : null}

        {children}
      </div>
    </LogErrorToServer>
  );
}

export function EmptyAppFrame({ children }: { children: React.ReactNode }) {
  const helpModalController = useHelpModalController();

  return (
    <LogErrorToServer>
      <HelpModalContext.Provider value={helpModalController}>
        {children}

        {helpModalController.isOpen && (
          <HelpModal
            organisations={helpModalController.organisations}
            helpUrl="/api/help"
            onClose={helpModalController.close}
            isUserflowBlocked={helpModalController.isUserflowBlocked}
          />
        )}
      </HelpModalContext.Provider>
    </LogErrorToServer>
  );
}

const isPrivateMode = () =>
  document.cookie
    .split(";")
    .map(c => c.trim())
    .filter(c => c === "private-mode=1").length > 0;
