import * as React from "react";
import { Alert } from "@/shared/Alert";
import Button from "@/shared/Button";
import Icon from "@/shared/Icon";
import { Checkbox } from "@/shared/input-components/Checkbox";
import { insightFetch } from "@/shared/insightFetch";
import { LoginData, LoginPageData } from "@server-types/public";
import OutOfDateBrowser from "./OutOfDateBrowser";
import styles from "./App.css";

export function App({ pageData }: { pageData: LoginPageData }) {
  const initialFormData: LoginData = {
    emailAddress: "",
    password: "",
    authenticatorCode: null,
    isPersistent: false,
    afterLogin: pageData.links?.afterLogin
  };

  const [formData, setFormData] = React.useState<LoginData>(initialFormData);
  const [isEnteringCode, setIsEnteringCode] = React.useState(false);
  const [isLoggingIn, setIsLoggingIn] = React.useState(false);
  const [error, setError] = React.useState("");
  const ios = React.useMemo(getIosDetails, []);

  function getIosDetails() {
    if (navigator.userAgent.match(/ipad|iphone|ipod/i)) {
      //if the current device is an iDevice
      const ios_info = {
        fullReleaseNumeric: parseFloat(
          navigator.userAgent
            .match(/OS (\d)?\d_\d(_\d)?/i)[0]
            .replace("_", ".")
            .replace("_", "")
            .replace("OS ", "")
        ) //converts versions to numeric value eg. 10.3.3 for ease of numeric comparisons
      };
      return ios_info;
    }
  }

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setError("");
    setIsLoggingIn(true);

    try {
      const response = await insightFetch(pageData.links.login, {
        method: "POST",
        body: formData
      });

      const data = await response.json();

      if (response.ok) {
        if (data.authenticatorCodeRequired) {
          setIsEnteringCode(true);
          setIsLoggingIn(false);
        } else {
          window.location = data.links.redirect;
        }
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      if (error.message === "incorrectCredentials") {
        setError(
          "The email address or password you entered doesn't match our records. Please try again."
        );
      } else if (error.message === "invalidAuthenticatorCode") {
        setError(
          "The authenticator code you entered is incorrect. Please try again."
        );
      } else {
        setError(
          "Sorry, something went wrong and we couldn't log you in. Please try again."
        );
      }
      setIsLoggingIn(false);
    }
  }

  return (
    <div className={styles.wrapper}>
      {ios && ios.fullReleaseNumeric < 10.3 && <OutOfDateBrowser />}

      <form className={styles.form} onSubmit={handleSubmit}>
        <h1>Log in</h1>

        {pageData.alert.text && (
          <div style={{ marginBottom: 20 }}>
            <Alert type="warning">
              {pageData.alert.text}
              {pageData.alert.showStatusLink && (
                <div style={{ marginTop: 10 }}>
                  Check the{" "}
                  <a
                    href="https://status.insighttracking.com"
                    rel="noreferrer"
                    target="_blank"
                  >
                    status page
                  </a>{" "}
                  for updates.
                </div>
              )}
            </Alert>
          </div>
        )}

        {isEnteringCode ? (
          <div className="control-group">
            <label
              htmlFor="authenticator-code"
              className={"control-label " + styles.label}
            >
              Authenticator Code:
            </label>
            <div className="controls">
              <input
                className="input-block-level"
                id="authenticator-code"
                tabIndex={1}
                type="text"
                name="authenticatorCode"
                autoComplete="off"
                required
                autoFocus
                style={{ fontSize: "16px" }}
                onChange={e =>
                  setFormData({
                    ...formData,
                    authenticatorCode: e.target.value
                      .replace(/\s/g, "")
                      .toLowerCase()
                  })
                }
              />
            </div>
          </div>
        ) : (
          <React.Fragment>
            <div className="control-group">
              <label
                htmlFor="email-address"
                className={"control-label " + styles.label}
              >
                Email address or username:
              </label>
              <div className="controls">
                <input
                  className="input-block-level"
                  id="email-address"
                  tabIndex={1}
                  type="text"
                  name="emailAddress"
                  autoComplete="off"
                  required
                  autoFocus
                  style={{ fontSize: "16px" }}
                  onChange={e =>
                    setFormData({
                      ...formData,
                      emailAddress: e.target.value
                    })
                  }
                />
              </div>
            </div>

            <div className="control-group">
              <span className="pull-right" style={{ fontSize: "13px" }}>
                <a href={pageData.links.forgotPassword} target="_self">
                  Forgotten your password?
                </a>
              </span>
              <label
                htmlFor="password"
                className={"control-label " + styles.label}
              >
                Password:
              </label>
              <div className="controls">
                <input
                  className="input-block-level"
                  id="password"
                  tabIndex={2}
                  type="password"
                  name="password"
                  autoComplete="off"
                  required
                  style={{ fontSize: "16px" }}
                  onChange={e =>
                    setFormData({
                      ...formData,
                      password: e.target.value
                    })
                  }
                />
              </div>
            </div>
          </React.Fragment>
        )}

        {error && <Alert type="error">{error}</Alert>}

        <div
          className="form-inline control-group"
          style={{ marginTop: "16px" }}
        >
          <Button
            type="submit"
            variant="primary"
            icon="lock"
            tabIndex={4}
            busy={isLoggingIn}
          >
            Log in
          </Button>
          {!isEnteringCode && (
            <Checkbox
              label="Keep me logged in on this device"
              labelClassName={styles.checkboxLabel}
              onChange={() =>
                setFormData({
                  ...formData,
                  isPersistent: !formData.isPersistent
                })
              }
              tabIndex={3}
            />
          )}
        </div>

        {formData.isPersistent && (
          <Alert type="warning">
            <div style={{ fontSize: "13px" }}>
              <Icon icon="warning" /> If using a shared/public device, please
              remember to log out when finished.
            </div>
          </Alert>
        )}

        <div className={styles.helpBlock}>Need help? Call 020 3393 4005.</div>

        <div className={styles.helpBlock} style={{ fontSize: "12px" }}>
          By logging in you are agreeing to Insight&apos;s{" "}
          <a href="https://www.insighttracking.com/terms/">Terms of Service</a>.
        </div>
      </form>
    </div>
  );
}
