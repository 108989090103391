import classNames from "classnames";
import * as React from "react";
import { GetSchoolLayoutData } from "@server-types/schools";
import Icon from "../Icon";
import { useCurrentUser } from "../useCurrentUser";
import { HelpLink } from "./HelpLink";
import {
  CohortsMenu,
  EnterDataMenu,
  MyAccountMenu,
  PrimaryLink,
  SecondaryLink
} from "./Menus";
import { OrganisationSelect } from "./OrganisationSelect";
import SearchPupils from "./SearchPupils";
import SearchPupilsModal from "./SearchPupilsModal";
import styles from "./index.css";

export default function SchoolNavBar({
  layout
}: {
  layout: GetSchoolLayoutData;
}) {
  const [modalVisible, setModalVisible] = React.useState(false);
  const links = layout.links;
  const currentUser = useCurrentUser();
  const orgCount = React.useMemo(
    () =>
      currentUser?.organisations.reduce(
        (count, org) => count + org.organisations.length,
        currentUser?.organisations.length
      ),
    [currentUser?.organisations]
  );
  const hasMultipleOrganisations = orgCount > 1;

  return (
    <>
      <div id="navbar" className={styles.navbar}>
        <div className={styles.primaryNav}>
          <PrimaryLink icon="home" text="Home" href={links.home} />{" "}
          <PrimaryLink
            icon="chart-column"
            iconStyle="far"
            text="Reports"
            createDropdown={() => <CohortsMenu links={links} />}
          />{" "}
          <PrimaryLink icon="user" text="Pupils" href={links.pupils} />{" "}
          <PrimaryLink
            icon="compass"
            text="Provisions"
            href={links.provisionMapping}
          />{" "}
          <PrimaryLink
            icon="check-circle"
            text="Objectives"
            href={links.objectives}
          />{" "}
          {links.enterAssessments && (
            <>
              <PrimaryLink
                icon="plus-circle"
                text="Enter Data"
                createDropdown={() => <EnterDataMenu links={links} />}
              />{" "}
            </>
          )}
          {links.search && (
            <button
              type="button"
              className={classNames(styles.primaryNavLink, styles.searchButton)}
              onClick={() => setModalVisible(true)}
              title="Search pupils"
            >
              <Icon icon="search" />
            </button>
          )}
        </div>

        {links.search && (
          <div className={styles.form}>
            <SearchPupils link={links.search} />
          </div>
        )}

        <div className={styles.secondaryNav}>
          {hasMultipleOrganisations ? (
            <OrganisationSelect
              currentOrganisationName={layout.schoolName}
              organisations={currentUser.organisations}
            />
          ) : (
            <div className={styles.organisationName}>{layout.schoolName}</div>
          )}

          <SecondaryLink icon="ellipsis-v" muted href={links.sysAdmin} />
          <HelpLink
            organisationId={"Schools/" + layout.schoolId}
            organisationName={layout.schoolName}
          />

          <MyAccountMenu links={links} />
        </div>
      </div>

      {modalVisible && (
        <SearchPupilsModal
          link={links.search}
          onCancel={() => setModalVisible(false)}
        />
      )}
    </>
  );
}
