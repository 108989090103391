import classNames from "classnames";
import * as React from "react";
import styles from "./CheckboxList.css";

type CheckboxProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  // Remove style to ensure we use labelStyle instead.
  // This should make it less ambiguous where the style is applied.
  "style"
> & {
  /** The label text to show next to the checkbox. */
  label: string;
  /** Optional CSS class name to apply to the label. */
  labelClassName?: string;
  /** Optional CSS style to apply to the label. */
  labelStyle?: React.CSSProperties;
};

export function Checkbox({
  label,
  labelClassName,
  labelStyle,
  ...rest
}: CheckboxProps) {
  return (
    <label
      className={classNames(styles.checkboxLabel, labelClassName)}
      style={labelStyle}
    >
      <input type="checkbox" className={styles.checkbox} {...rest} /> {label}
    </label>
  );
}
