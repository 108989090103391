import * as React from "react";
import { GetTrustLayoutData, GetTrustLayoutLinks } from "@server-types/trusts";
import { useFeatures } from "../AppConfig";
import { useCurrentUser } from "../useCurrentUser";
import { HelpLink } from "./HelpLink";
import { MenuLink, MyAccountMenu, PrimaryLink, SecondaryLink } from "./Menus";
import { OrganisationSelect } from "./OrganisationSelect";
import styles from "./index.css";

export default function TrustNavBar({
  layout
}: {
  layout: GetTrustLayoutData;
}) {
  const links = layout.links;
  const currentUser = useCurrentUser();
  const orgCount = React.useMemo(
    () =>
      currentUser?.organisations.reduce(
        (count, org) => count + org.organisations.length,
        currentUser?.organisations.length
      ),
    [currentUser?.organisations]
  );
  const hasMultipleOrganisations = orgCount > 1;

  if (!currentUser) {
    return null;
  }

  return (
    <div id="navbar" className={styles.navbar}>
      <div className={styles.primaryNav}>
        <PrimaryLink icon="home" text="Home" href={links.home} />{" "}
        <PrimaryLink
          icon="chart-column"
          iconStyle="far"
          text="Reports"
          createDropdown={() => <ReportsMenu links={links} />}
        />{" "}
        {currentUser.accessLevel !== "MosaicOnly" && (
          <PrimaryLink
            icon="plus-circle"
            text="Enter Data"
            createDropdown={() => <EnterDataMenu links={links} />}
          />
        )}{" "}
      </div>

      <div className={styles.secondaryNav}>
        {hasMultipleOrganisations ? (
          <OrganisationSelect
            currentOrganisationName={layout.trustName}
            organisations={currentUser.organisations}
          />
        ) : (
          <div className={styles.organisationName}>{layout.trustName}</div>
        )}

        <SecondaryLink icon="ellipsis-v" muted href={links.sysAdmin} />
        <HelpLink
          organisationId={layout.trustId}
          organisationName={layout.trustName}
        />

        <MyAccountMenu links={links} />
      </div>
    </div>
  );
}

function ReportsMenu({ links }: { links: GetTrustLayoutLinks }) {
  const features = useFeatures();
  return (
    <ul className={styles.menu}>
      <MenuLink
        icon="users"
        text="Demographics"
        href={links.reportsDemographics}
      />

      {links.attendance && (
        <MenuLink
          icon="calendar-alt"
          text="Attendance"
          href={links.attendance}
        />
      )}

      {features.includes("Trust Statutory Assessments") && (
        <MenuLink
          icon="archive"
          text="Statutory Assessments"
          href={links.statutoryAssessments}
        />
      )}

      <MenuLink
        icon="tasks-alt"
        text="Overviews – Attainment"
        href={links.attainmentOverview}
      />
      <MenuLink
        icon="tasks-alt"
        text="Overviews – Progress"
        href={links.reportsProgressOverview}
      />
      <MenuLink
        icon="dot-circle"
        iconStyle="far"
        text="Scatter Graph"
        href={links.reportsScatterChart}
      />
      <MenuLink
        icon="th-large"
        text="Progress Matrix"
        href={links.reportsProgressMatrix}
      />
      <MenuLink icon="grid" text="Mosaic" href={links.mosaic} />
      <MenuLink icon="table" text="Tables" href={links.tables} />
      <MenuLink icon="list-alt" text="Headlines" href={links.headlineReports} />
    </ul>
  );
}

export function EnterDataMenu({ links }: { links: GetTrustLayoutLinks }) {
  return (
    <ul className={styles.menu}>
      <MenuLink
        icon="upload"
        text="Smartgrade Import"
        href={links.smartGrade}
      />
    </ul>
  );
}
