import React from "react";
import Button from "../Button";
import {
  ModalWindow,
  ModalWindowBody,
  ModalWindowFooter
} from "../ModalWindow";
import SearchPupils from "./SearchPupils";
import styles from "./SearchPupilsModal.css";

export default function SearchPupilsModal({
  link,
  onCancel
}: {
  link: string;
  onCancel: VoidFunction;
}) {
  return (
    <ModalWindow title="Search Pupils" onClose={onCancel}>
      <ModalWindowBody>
        <SearchPupils
          link={link}
          inputClassName={styles.searchInput}
          autoFocus
        />
      </ModalWindowBody>
      <ModalWindowFooter>
        <Button onClick={onCancel}>Cancel</Button>
      </ModalWindowFooter>
    </ModalWindow>
  );
}
