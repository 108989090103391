import * as React from "react";
import Icon from "./Icon";

export default function Spinner({
  style = null
}: {
  style?: React.CSSProperties;
}) {
  return <Icon icon="spinner" spin style={style} />;
}
