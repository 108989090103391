import { IconName, IconPrefix } from "@fortawesome/fontawesome-svg-core";
import {
  FontAwesomeIcon,
  FontAwesomeIconProps
} from "@fortawesome/react-fontawesome";
import * as React from "react";

export type IconStyle = Omit<IconPrefix, "fak">;

type Props = Omit<FontAwesomeIconProps, "icon"> & {
  icon: IconName;
  iconStyle?: IconStyle;
};

export default function Icon({
  icon,
  // iconStyle is used by the babel plugin to load the icon from the correct package
  iconStyle, // eslint-disable-line @typescript-eslint/no-unused-vars
  ...rest
}: Props) {
  return <FontAwesomeIcon icon={icon} {...rest} />;
}
